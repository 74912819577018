import { getCurrentYear, openLoginRegistrationPopup } from "@/libs/helpers.js"
import { apply_is_added, apply_is_fav } from "@/libs/eventBus.js"
import _debounce from "lodash/debounce"
import { createPinia } from "pinia"
import { createApp } from "vue"

const SmartSearch = () => import("@/components/Frontend/SmartSearch.vue")
const SmartSearchFilters = () => import("@/components/Frontend/SmartSearchFilters.vue")
const SmartSearchResults = () => import("@/components/Frontend/SmartSearchResults.vue")
const BoxCallAction = () => import("@/components/Frontend/BoxCallAction.vue")
const HeaderBasket = () => import("@/components/Frontend/HeaderBasket.vue")
const SearchResult = () => import("@/components/Frontend/SearchResult.vue")
const ContentCard = () => import("@/components/Frontend/ContentCard.vue")
const PackageCard = () => import("@/components/Frontend/PackageCard.vue")
const LoginForm = () => import("@/components/Frontend/LoginForm.vue")
const CookieBanner = () => import("@/components/Frontend/CookieBanner.vue")

;(() => {
    const pinia = createPinia()

    const elements = {
        searchField: document.querySelector("#search"),
        searchButton: document.getElementById("header-search-button"),
        searchClose: document.getElementById("close-search-field"),
        menuItems: document.querySelectorAll("#main-nav > ul > li"),
        subMenuItems: document.querySelectorAll("#main-nav > ul > li > ul > li > a"),
        hamburgerMenu: document.querySelector("#hamburger-menu"),
        mainNav: document.querySelector("#main-nav"),
        popupBladeClose: document.querySelectorAll(".popup-blade > .popup-backdrop, .popup-blade .popup-close"),
        showPass: document.querySelectorAll(".password-control"),
        passwordFields: document.querySelectorAll('input[type="password"]'),
        logoutBtns: document.querySelectorAll(".logoutBtn"),
        logoutPopup: document.getElementById("logout-popup"),
        app: document.getElementById("app"),
        csrf: document.querySelector("meta[name=csrf-token]").content,
        inputToken: document.querySelectorAll("input[name=_token]"),
        historyGoBackBtns: document.querySelectorAll(".historyGoBack"),
        useTextTutorialsBtns: document.querySelectorAll(".useTextTutorials"),
        usePdfTutorialsBtns: document.querySelectorAll(".usePdfTutorials"),
        useSingleContentBtns: document.querySelectorAll(".useSingleContent"),
        useContentNewTabBtns: document.querySelectorAll(".useContentNewTab"),
        useContentBtns: document.querySelectorAll(".useContent"),
        buttonsDownloadContent: document.querySelectorAll(
            ".button-download-content, .button-add-package, .button-add-content",
        ),
        seoVideo: document.querySelector("#seoVideo"),
        seoVideoButton: document.querySelector("#seoVideoButton"),
        seoVideoCover: document.querySelector("#seoVideoCover"),
    }

    if (window.location.hash === "#video-tour-durch-das-portal") {
        setTimeout(() => {
            const element = document.getElementById(window.location.hash.substring(1))
            if (element) element.scrollIntoView(true)
        }, 2000)
    }

    window.addEventListener(
        "scroll",
        _debounce(() => {
            document.body.classList.toggle("scrolled", window.scrollY > 50)
        }, 50),
        { passive: true },
    )

    // if (elements.searchButton || elements.searchField) {
    //     let searchAppLoaded = false
    //
    //     const handleSearchClick = (event) => {
    //         event.stopPropagation()
    //         if (!searchAppLoaded) initHeaderVueApp(pinia)
    //         searchAppLoaded = true
    //     }
    //
    //     elements.searchButton.addEventListener("click", handleSearchClick, { passive: true })
    //     elements.searchField.addEventListener("click", handleSearchClick, { passive: true })
    //
    //     if (window.visualViewport.width <= 740) {
    //         elements.searchButton.addEventListener("click", (event) => event.preventDefault())
    //     }
    // }

    elements.hamburgerMenu.addEventListener(
        "click",
        () => {
            document.body.classList.toggle("menu-open")
            outsideUserMenuClickListener()
        },
        { passive: true },
    )

    elements.menuItems.forEach((menuItem) => {
        menuItem.addEventListener(
            "click",
            (event) => {
                elements.menuItems.forEach((item) => {
                    if (!item.contains(event.target) && item.classList.contains("expanded")) {
                        item.classList.remove("expanded")
                    }
                })
                if (isMobileMenuActive() && hasSubMenu(menuItem)) {
                    event.stopPropagation()
                    event.preventDefault()
                    menuItem.classList.toggle("expanded")
                }
            },
            { passive: false },
        )
    })

    elements.subMenuItems.forEach((subMenuItem) => {
        subMenuItem.addEventListener("click", (event) => event.stopPropagation(), { passive: true })
    })

    document.addEventListener("click", outsideMenuClickListener, { passive: true })

    const isMobileMenuActive = () => getComputedStyle(elements.hamburgerMenu).display !== "none"
    const hasSubMenu = (menuItem) => menuItem.classList.contains("has-sub-menu")

    function outsideMenuClickListener(event) {
        if (!elements.mainNav.contains(event.target) && !elements.hamburgerMenu.contains(event.target)) {
            closeMobileMenu()
        }
    }

    function closeMobileMenu() {
        document.body.classList.remove("menu-open")
    }

    setTimeout(() => {
        document.body.addEventListener("touchmove", outsideUserMenuClickListener, { passive: true })
        document.body.addEventListener("scroll", outsideUserMenuClickListener, { passive: true })
        document.addEventListener(
            "mouseup",
            (e) => {
                const user = document.querySelector(".user-sub-menu")
                if (user && user.classList.contains("open-sub-menu") && !user.contains(e.target)) {
                    outsideUserMenuClickListener()
                }
            },
            { passive: true },
        )
    }, 1000)

    function bindUserMenuBtn() {
        const userIcon = document.querySelector("#btn-login")

        let loginAppLoaded = false

        if (userIcon) {
            userIcon.addEventListener(
                "click",
                (e) => {
                    e.preventDefault()
                    const liItem = e.target.closest(".has-sub-menu")
                    if (liItem) {
                        liItem.classList.toggle("open-sub-menu")
                        liItem.querySelector("ul").classList.toggle("open-sublist-menu")
                    }
                    if (!loginAppLoaded) initLoginVueApp(pinia, true)
                    loginAppLoaded = true
                    openLoginRegistrationPopup("login")
                },
                { passive: false },
            )
        }

        document
            .querySelectorAll(
                "form.test-account .tutkit-email, .wp-block-tutkit-email-form .tutkit-email, form.test-account button, .wp-block-tutkit-email-form button",
            )
            .forEach((element) => {
                element.addEventListener(
                    "click",
                    (e) => {
                        e.preventDefault()
                        if (!loginAppLoaded) initLoginVueApp(pinia, false)
                        loginAppLoaded = true
                    },
                    { passive: false },
                )
            })
    }

    bindUserMenuBtn()

    function outsideUserMenuClickListener() {
        document.querySelectorAll(".has-sub-menu").forEach((item) => {
            if (item.classList.contains("open-sub-menu")) {
                item.classList.remove("open-sub-menu")
                item.querySelector("ul").classList.toggle("open-sublist-menu")
            }
        })
    }

    elements.mainNav.addEventListener("touchstart", handleTouchStart, { passive: true })
    elements.mainNav.addEventListener("touchmove", handleTouchMove, { passive: true })

    let xDown = null
    let yDown = null

    function getTouches(evt) {
        return evt.touches || evt.originalEvent.touches
    }

    function handleTouchStart(evt) {
        const firstTouch = getTouches(evt)[0]
        xDown = firstTouch.clientX
        yDown = firstTouch.clientY
    }

    function handleTouchMove(evt) {
        if (!xDown || !yDown) return

        const xUp = evt.touches[0].clientX
        const yUp = evt.touches[0].clientY
        const xDiff = xDown - xUp
        const yDiff = yDown - yUp
        const swipeMinDistance = 5

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > swipeMinDistance) closeMobileMenu()
        }
        xDown = null
        yDown = null
    }

    elements.popupBladeClose.forEach((closeElement) => {
        closeElement.addEventListener(
            "click",
            (event) => {
                event.preventDefault()
                event.target.closest(".popup-blade").classList.remove("popup-open")
            },
            { passive: true },
        )
    })

    document.addEventListener(
        "keydown",
        (evt) => {
            if (evt.key === "Escape" || evt.key === "Esc" || evt.keyCode === 27) {
                const popupBlade = document.querySelector(".popup-blade")
                if (popupBlade) popupBlade.classList.remove("popup-open")
            }
        },
        { passive: true },
    )

    if (elements.showPass.length > 0) {
        show_hide_password(elements.showPass)
    }

    function show_hide_password(arr) {
        arr.forEach((item) => {
            item.addEventListener("click", (e) => {
                e.preventDefault()
                const input = item.previousElementSibling
                const type = input.getAttribute("type") === "password" ? "text" : "password"
                item.classList.toggle("view", type === "text")
                input.setAttribute("type", type)
            })
        })
    }

    elements.passwordFields.forEach((passwordField) => {
        passwordField.addEventListener(
            "input",
            async (event) => {
                const { passwordStrength } = await import("@/libs/password.js")
                passwordStrength(event.target.value)
            },
            { passive: true },
        )
    })

    if (elements.logoutBtns.length > 0) {
        elements.logoutBtns.forEach((logoutBtn) => {
            logoutBtn.addEventListener("click", (event) => {
                event.preventDefault()
                elements.logoutPopup.classList.add("popup-open")
                const token = document.querySelector('meta[name="csrf-token"]').getAttribute("content")
                const request = new XMLHttpRequest()
                request.open("POST", "/logout", true)
                request.setRequestHeader("Content-Type", "application/json; charset=UTF-8")
                request.setRequestHeader("X-CSRF-TOKEN", token)
                request.onload = () => {
                    if (request.readyState === 4 && request.status === 200) {
                        setTimeout(() => (window.location.href = `/${window.globalJs.route.locale}`), 5000)
                    }
                }
                let data = JSON.stringify({ _token: token })
                request.send(data)
            })
        })
    }

    document.addEventListener(
        "DOMContentLoaded",
        () => {
            let query = false
            if (window.location.search) {
                const match = window.location.search.match(/(\?|&)q=([^&]*)/)
                if (match) {
                    query = decodeURIComponent(match[2])
                }
            }

            switch (query) {
                case "login":
                    setTimeout(() => {
                        document.querySelector("#btn-login").click()
                    }, 2000)
                    break
                case "register":
                    setTimeout(() => {
                        document.querySelector("#btn-login").click()
                        openLoginRegistrationPopup("registration")
                    }, 2000)
                    break
            }

            document.querySelectorAll(".currentYear").forEach((item) => {
                item.innerText = getCurrentYear()
            })
        },
        { passive: true },
    )

    elements.inputToken.forEach((e) => (e.value = elements.csrf))

    const submitVerifyResend = document.getElementById("submit-verify-resend")
    if (submitVerifyResend) {
        submitVerifyResend.addEventListener(
            "submit",
            (e) => {
                e.preventDefault()
                fetch(e.target.action, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=UTF-8",
                        "X-CSRF-TOKEN": elements.csrf,
                    },
                })
                    .then((res) => res.status)
                    .then((response) => {
                        if (response) {
                            const protocol = window.location.href.indexOf("https://") === 0 ? "https://" : "http://"
                            window.location.href = `${protocol}${window.location.host}/email/verify?sent=1`
                        }
                    })
            },
            { passive: true },
        )
    }

    setTimeout(() => {
        modifyRelForAllExternalLinks()
    }, 1)

    if (document.body.classList.contains("logged-in")) {
        document.querySelectorAll(".footer-green-section").forEach((item) => {
            item.style.display = "none"
        })
    }

    if (document.getElementById("package-details")) {
        const packageNeedsReloadInterval = setInterval(packageNeedsReload, 100)
        packageNeedsReload(packageNeedsReloadInterval)
    } else if (!document.getElementById("package-details") && !document.querySelector(".cart-page")) {
        sessionStorage.removeItem("package_needs_reload")
    }

    function packageNeedsReload(id) {
        if (sessionStorage.getItem("package_needs_reload")) {
            sessionStorage.removeItem("package_needs_reload")
            clearInterval(id)
            location.reload()
        }
    }

    elements.historyGoBackBtns.forEach((historyGoBackBtn) => {
        historyGoBackBtn.addEventListener(
            "click",
            () => {
                window.history.go(-1)
            },
            { passive: true },
        )
    })

    document.addEventListener(
        "click",
        (event) => {
            if (event.target.classList.contains("certificateDownload")) {
                const idNumber = event.target.dataset.id
                const idName = idNumber ? `#certificate-icon-${idNumber}` : "#certificate-icon"
                const targetElement = document.querySelector(idName)
                if (targetElement) targetElement.classList.add("downloaded")
            }
        },
        { passive: true },
    )

    elements.useTextTutorialsBtns.forEach((useTextTutorialsBtn) => {
        useTextTutorialsBtn.addEventListener(
            "click",
            (event) => {
                window.useTextTutorials(event)
            },
            { passive: false },
        )
    })

    elements.usePdfTutorialsBtns.forEach((usePdfTutorialsBtn) => {
        usePdfTutorialsBtn.addEventListener(
            "click",
            (event) => {
                window.usePdfTutorials(event)
            },
            { passive: false },
        )
    })

    elements.useSingleContentBtns.forEach((useSingleContentBtn) => {
        useSingleContentBtn.addEventListener(
            "click",
            () => {
                window.useSingleContent(useSingleContentBtn.dataset.link)
            },
            { passive: false },
        )
    })

    elements.useContentNewTabBtns.forEach((useContentNewTabBtn) => {
        useContentNewTabBtn.addEventListener(
            "click",
            (event) => {
                window.useContentNewTab(useContentNewTabBtn.dataset.link, event)
            },
            { passive: false },
        )
    })

    elements.useContentBtns.forEach((useContentBtn) => {
        useContentBtn.addEventListener(
            "click",
            (event) => {
                window.useContent(useContentBtn.dataset.link, event)
            },
            { passive: false },
        )
    })

    setTimeout(() => {
        elements.buttonsDownloadContent.forEach((buttonDownloadContent) => {
            buttonDownloadContent.addEventListener(
                "click",
                (event) => {
                    event.preventDefault()
                },
                { passive: false },
            )
        })
    }, 1000)

    if (elements.seoVideoCover && elements.seoVideoButton && elements.seoVideo) {
        setTimeout(playSeoVideoTimeout, 200)
    }

    function playSeoVideo() {
        elements.seoVideoCover.classList.add("not-active")
        elements.seoVideo.play()
    }

    function playSeoVideoTimeout() {
        elements.seoVideoButton.addEventListener("click", playSeoVideo, { passive: false })
    }

    function modifyRelForAllExternalLinks() {
        document.querySelectorAll("a").forEach((link) => {
            const a = new RegExp(`/${window.location.host}/`)
            if (!a.test(link.href)) {
                link.rel = `${link.rel} noreferrer noopener`.trim()
            }
        })
    }

    initCookieBannerVueApp(pinia)
    initDeferredVueApps(pinia, elements)

    const navigationCatalog = document.getElementById("navigationCatalog")
    const helpSearch = document.querySelector(".help-search")

    if (navigationCatalog) {
        let helpModuleLoaded = false

        const loadHelpModule = (activateModule) => {
            if (!helpModuleLoaded) {
                helpModuleLoaded = true
                import("@/frontend/help.js")
                    .then((module) => {
                        module.default(activateModule)
                    })
                    .catch((err) => {
                        console.error("Failed to load help.js module:", err)
                    })
            }
        }

        navigationCatalog.addEventListener("click", () => loadHelpModule("navigation"), { passive: true })
        helpSearch.addEventListener("click", () => loadHelpModule("help"), { passive: true })

        if (window.matchMedia("(min-width: 992px)").matches) {
            navigationCatalog.click()
        }
    }

    const translateRedirect = document.getElementById('translate-redirect');
    let translation = "";
    let close = "";
    const currentLocale = translateRedirect.dataset.currentLocale;
    const browserLanguage = navigator.language || navigator.userLanguage;
    const browserLanguageName = new Intl.DisplayNames(['en'], { type: 'language' }).of(browserLanguage);

    if (localStorage && !localStorage.getItem("translation") && currentLocale !== browserLanguage) {
        checkLanguage();
    }

    async function checkLanguage() {
        try {
            const response = await fetch('/check-language', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
                },
                body: JSON.stringify({
                    language_code: browserLanguage,
                    url: window.location.href
                })
            });
            const data = await response.json();
            if (data.status) {
                translation = data.translation;
                close = data.close;
                createNotification();
            }
        } catch (error) {
            console.error("Error while checking language", error);
        }
    }

    function createNotification() {
        translateRedirect.style.display = 'block';

        const closeButton = document.createElement('a');
        closeButton.href = '#';
        closeButton.className = 'popup-close icon icon-close-light';
        closeButton.title = close;
        closeButton.addEventListener('click', (event) => {
            event.preventDefault();
            localStorage.setItem("translation", "true");
            translateRedirect.remove();
        });

        const wrapper = document.createElement('div');
        wrapper.className = 'wrapper';

        const titleElement = document.createElement('p');
        titleElement.className = 'h2';
        titleElement.textContent = translation.title;

        const textElement = document.createElement('p');
        textElement.textContent = translation.text;

        const changeLanguageButton = document.createElement('button');
        changeLanguageButton.type = 'button';
        changeLanguageButton.className = 'button button-high button-primary';

        const buttonInner = document.createElement('div');
        buttonInner.className = 'button-inner';

        const buttonLabel = document.createElement('span');
        buttonLabel.className = 'button-label';
        buttonLabel.textContent = `${translation.button} ${browserLanguageName}`;

        buttonInner.appendChild(buttonLabel);
        changeLanguageButton.appendChild(buttonInner);

        changeLanguageButton.addEventListener('click', async () => {
            try {
                const response = await fetch('/change-language', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json; charset=UTF-8",
                        "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').content,
                    },
                    body: JSON.stringify({
                        language_code: browserLanguage,
                        url: window.location.href
                    })
                });
                const data = await response.json();
                localStorage.setItem("translation", "true");
                translateRedirect.remove();
                window.location.href = data.url;
            } catch (error) {
                console.error("Error while changing language", error);
            }
        });

        wrapper.appendChild(titleElement);
        wrapper.appendChild(textElement);
        translateRedirect.appendChild(closeButton);
        translateRedirect.appendChild(wrapper);
        translateRedirect.appendChild(changeLanguageButton);
    }
})()

function initHeaderVueApp(pinia) {
    const VueApp = createApp({})
    VueApp.config.compilerOptions.whitespace = "preserve"
    VueApp.component("smart-search", SmartSearch)
        .component("box-call-action", BoxCallAction)
        .component("header-basket", HeaderBasket)
    VueApp.use(pinia)
    VueApp.mount(".search-app")
}

function initLoginVueApp(pinia, open = true) {
    const LoginApp = createApp({})
    LoginApp.config.compilerOptions.whitespace = "preserve"
    LoginApp.provide("open", open)
    LoginApp.component("login-form", LoginForm)
    LoginApp.mount("#login-form-popup")
}

function initSearchPageApp(pinia) {
    initHeaderVueApp(pinia)
    const SearchApp = createApp({})
    SearchApp.config.compilerOptions.whitespace = "preserve"

    SearchApp.component("smart-search-filters", SmartSearchFilters)
        .component("smart-search-results", SmartSearchResults)
        .component("search-result", SearchResult)
        .component("content-card", ContentCard)
        .component("package-card", PackageCard)
    SearchApp.mount("#app")

    apply_is_added()
    apply_is_fav()

    const packageSearchButton = document.getElementById("package_search")
    const contentSearchButton = document.getElementById("content_search")
    const toggleSearchTypeButtons = document.querySelectorAll(".toggle-search-type")

    if (packageSearchButton) {
        packageSearchButton.addEventListener("click", () => {
            window.location.href = window.location.href.replace("/einzelinhalte?", "/gesamtpakete?")
        })
    }

    if (contentSearchButton) {
        contentSearchButton.addEventListener("click", () => {
            window.location.href = window.location.href.replace("/gesamtpakete?", "/einzelinhalte?")
        })
    }

    if (toggleSearchTypeButtons.length > 0) {
        toggleSearchTypeButtons.forEach((button) => {
            button.addEventListener("click", () => {
                if (button.classList.contains("is-active")) {
                    contentSearchButton.click()
                    button.classList.remove("is-active")
                } else {
                    packageSearchButton.click()
                    button.classList.add("is-active")
                }
            })
        })
    }
}

function initCookieBannerVueApp(pinia) {
    const cookieBannerWrapper = document.getElementById("cookie-banner-wrapper")
    if (cookieBannerWrapper) {
        const CookieBannerApp = createApp({})
        CookieBannerApp.config.compilerOptions.whitespace = "preserve"
        CookieBannerApp.component("cookie-banner", CookieBanner)
        CookieBannerApp.mount("#cookie-banner-wrapper")
    }
}

// function bindUserMenuBtn() {
//     const userIcon = document.querySelector("#btn-login")
//
//     if (userIcon) {
//         userIcon.addEventListener(
//             "click",
//             (e) => {
//                 e.preventDefault()
//                 toggleUserMenuOrAuth(e)
//             },
//             { passive: false },
//         )
//     }
// }

function toggleUserMenuOrAuth(event) {
    event.preventDefault()
    if (document.body.classList.contains("logged-in") || document.body.dataset.userId != "") {
        const liItem = event.target.closest(".has-sub-menu")
        if (liItem) {
            liItem.classList.toggle("open-sub-menu")
            liItem.querySelector("ul").classList.toggle("open-sublist-menu")
        }
    } else {
        openLoginRegistrationPopup("login")
    }
}

function initDeferredVueApps(pinia, elements) {
    if (elements.app && elements.app.querySelector(".search-page")) {
        initSearchPageApp(pinia)
    } else {
        initHeaderVueApp(pinia)
    }
}
